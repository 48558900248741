import { useDocumentTitle } from '@/utils/hooks/useDocumentTitle';
import { useTranslation } from 'react-i18next';
import Typography from '@/components/common/typography/Typography';
import ContentBox from '@/components/common/content-box/ContentBox';
import styles from './FeatureFlags.module.scss';

export default function FeatureFlags(): React.JSX.Element {
  const { t } = useTranslation('configurations');
  useDocumentTitle(t('countries-page-title'));

  return (
    <ContentBox>
      <div className={styles.title}>
        <Typography variant='p1SemiBold'>Feature flags</Typography>
      </div>
    </ContentBox>
  );
}
