import { createAsyncThunk } from '@reduxjs/toolkit';
import { CustomerDocStatus, KycApplicationActionState } from '@/types';
import authAxios from '@/auth/axios';
import { successToast, errorToast } from '@/utils/toastMessage';

export const updateStatus = createAsyncThunk(
  'confirm-id-docs-review-action',
  async (
    {
      kycApplicationId,
      status,
      documentId,
    }: {
      kycApplicationId: string;
      status: CustomerDocStatus;
      documentId: string;
    },
    { rejectWithValue },
  ) => {
    try {
      const docsStatutesToKycApplicationActionStates: Record<string, KycApplicationActionState> = {
        [CustomerDocStatus.APPROVED]: KycApplicationActionState.AcceptDocument,
        [CustomerDocStatus.REJECTED]: KycApplicationActionState.RejectDocument,
      };

      await authAxios.post(`/kyc-applications/${kycApplicationId}/actions`, {
        action: docsStatutesToKycApplicationActionStates[status],
        documentId,
      });
      successToast('toast-messages.document-status-changed');
      return status;
    } catch (err: any) {
      errorToast();
      return rejectWithValue(err);
    }
  },
);
