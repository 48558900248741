import featureFlags from '@/assets/featureFlags.json';
import { EnvironmentConstants } from '@/utils/constants';

const featureFlagsData = featureFlags[EnvironmentConstants.RUNTIME_ENV] || {};

type FeatureFlags = {
  [K in FeatureFlagKeys]?: boolean;
};

enum FeatureFlagKeys {
  FeatureDailyLimits = 'featureDailyLimits',
  Configurations = 'configurations',
}

export { featureFlagsData, FeatureFlagKeys, type FeatureFlags };
