import { Fragment, type Dispatch, type SetStateAction } from 'react';
import { useAppSelector, type RootState } from '@/lib/redux';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { type Country } from '@/types';
import Typography from '@/components/common/typography/Typography';
import Input from '@/components/common/inputs/Input';
import InputLabel from '@/components/common/labels/InputLabel';
import { mediumPurple } from '@/utils/colors';
import Dropdown from '@/components/common/filters/Dropdown';
import styles from './CountryParameters.module.scss';

const GridItem = styled.div<{ $cell?: [number?, number?] }>`
  grid-column: ${({ $cell = [1, 1] }) => {
    const start = $cell[0];
    const span = $cell[1] ?? 1;
    return `${start} / span ${span}`;
  }};
  @media (max-width: 1090px) {
    grid-column: 1 / span 5;
  }
`;

enum Position {
  Left = 'left',
  Right = 'right',
}

interface CountryProps {
  parameters: Country;
  setParameters: Dispatch<SetStateAction<Country>>;
}

const CountryParameters = ({ parameters, setParameters }: CountryProps): JSX.Element => {
  const { t } = useTranslation('configurations');

  const { country } = useAppSelector((state: RootState) => state.country);

  const { countryCode, countryName, internationalCallingCode, minPhoneLength, maxPhoneLength, currencies } = parameters;

  const [local, second] = currencies;
  const countryInterface = [
    {
      id: 11,
      label: 'countryCode',
      value: countryCode,
      type: 'string',
      cell: [1, 2],
      upperCase: true,
      disabled: country.countryCode.length !== 0,
    },
    { id: 12, label: 'countryName', value: countryName, type: 'string', cell: [3, 2] },
    { id: 13, label: 'internationalCallingCode', value: internationalCallingCode, type: 'number', cell: [5, 2] },
    { id: 14, label: 'minPhoneLength', value: minPhoneLength, type: 'number', cell: [1, 2] },
    { id: 15, label: 'maxPhoneLength', value: maxPhoneLength, type: 'number', cell: [3, 2] },
    { id: 16, label: 'currencyName', value: local?.currencyName ?? '', type: 'string', cell: [1, 2], local: true },
    {
      id: 17,
      label: 'currencyCode',
      value: local?.currencyCode ?? '',
      type: 'string',
      cell: [3],
      local: true,
      upperCase: true,
    },
    { id: 18, label: 'symbol', value: local?.symbol ?? '', type: 'string', cell: [4], local: true },
    { id: 19, label: 'position', value: local?.position ?? '', type: 'string', cell: [5], local: true, select: true },
    { id: 20, label: 'precision', value: local?.precision ?? '', type: 'number', cell: [6], local: true },
    { id: 21, label: 'currencyName', value: second?.currencyName ?? '', type: 'string', cell: [1, 2], local: false },
    {
      id: 22,
      label: 'currencyCode',
      value: second?.currencyCode ?? '',
      type: 'string',
      cell: [3],
      local: false,
      upperCase: true,
    },
    { id: 23, label: 'symbol', value: second?.symbol ?? '', type: 'string', cell: [4], local: false },
    { id: 24, label: 'position', value: second?.position ?? '', type: 'string', cell: [5], local: false, select: true },
    { id: 25, label: 'precision', value: second?.precision ?? '', type: 'number', cell: [6], local: false },
  ];

  const positionOptions = [
    {
      text: 'Left',
      value: Position.Left,
    },
    {
      text: 'Right',
      value: Position.Right,
    },
  ];

  const updateCurrency = (index: 0 | 1, label: string, value: string | number): void => {
    setParameters(previousValue => {
      const updatedCurrencies = [...previousValue.currencies];
      updatedCurrencies[index] = {
        ...updatedCurrencies[index],
        [label]: value,
      };
      return { ...previousValue, currencies: updatedCurrencies };
    });
  };

  const handleCountryInterface = (value: string, item: { label: string; type: string; local?: boolean }): void => {
    let parsedValue: string | number = value;
    if (item.type === 'number') {
      parsedValue = Number(value);
      if (isNaN(parsedValue)) {
        return;
      }
    }
    if (item.local !== undefined) {
      updateCurrency(item.local ? 0 : 1, item.label, parsedValue);
    } else {
      setParameters(previousValue => ({ ...previousValue, [item.label]: parsedValue }));
    }
  };

  return (
    <div className={styles.card}>
      <div className={styles.cardContent}>
        <div className={styles.label}>
          <Typography
            variant='p3'
            color={mediumPurple.cssColor}
          >
            {t('new-country.country-parameters-description')}
          </Typography>
        </div>
        <div className={styles.inputCard}>
          <div className={styles.gridCard}>
            {countryInterface.map(item => (
              <Fragment key={item.id}>
                <GridItem $cell={item.cell as [number, number?]}>
                  <InputLabel label={t(`new-country.input-label.${item.label}`, { defaultValue: 'New type' })}>
                    {item.select === undefined && (
                      <Input
                        disabled={item.disabled}
                        onChange={(value: string) =>
                          handleCountryInterface(item?.upperCase ? value.toUpperCase() : value, item)
                        }
                        value={String(item.value)}
                      />
                    )}
                    {item.select && (
                      <Dropdown
                        options={positionOptions}
                        onSelect={position => handleCountryInterface(position.value ?? '', item)}
                        selectedOption={{
                          value: item.value,
                          text:
                            item.value && t(`new-country.position-options.${item.value}`, { defaultValue: 'New type' }),
                        }}
                        setSelectedOption={() => {}}
                      />
                    )}
                  </InputLabel>
                </GridItem>
                {item.id === 15 && (
                  <>
                    <GridItem $cell={[5, 2]} />
                    <GridItem $cell={[1, 6]}>
                      <Typography variant='p3Bold'>{t('new-country.local-currency')}</Typography>
                    </GridItem>
                  </>
                )}
                {item.id === 20 && (
                  <GridItem $cell={[1, 6]}>
                    <Typography variant='p3Bold'>{t('new-country.second-currency')}</Typography>
                  </GridItem>
                )}
              </Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CountryParameters;
