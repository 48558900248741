import { createSlice } from '@reduxjs/toolkit';
import { countries } from './actions';
import { type Countries } from '@/types';

export interface CountriesState {
  list: Countries[];
  isLoading: boolean;
}

const initialState: CountriesState = {
  list: [],
  isLoading: false,
};

export const countriesSlice = createSlice({
  name: 'countries',
  initialState,
  reducers: {
    resetCountries() {
      return { ...initialState };
    },
  },
  extraReducers: builder => {
    builder.addCase(countries.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(countries.fulfilled, (state, action) => {
      state.isLoading = false;
      state.list = action.payload;
    });
    builder.addCase(countries.rejected, state => {
      state.isLoading = false;
    });
  },
});

export const { resetCountries } = countriesSlice.actions;
