import { useAppDispatch, useAppSelector, type RootState } from '@/lib/redux';
import { useTranslation } from 'react-i18next';

import { useDocumentTitle } from '@/utils/hooks/useDocumentTitle';
import Typography from '@/components/common/typography/Typography';
import Table from '@/components/common/tables/Table';
import { CurrencyEnum, type BusinessAccounts } from '@/types';
import VerticalLine from '@/components/common/vertical-line/VerticalLine';
import { marine } from '@/utils/colors';
import styles from './Overview.module.scss';
import Spinner from '@/components/common/spinners/Spinner';
import countries from '@/i18n/countriesConfig';
import BusinessAccountActionButtons from '@/components/business-accounts/action-buttons/BusinessAccountActionButtons';
import { getBusinessAccounts } from '@/lib/redux/slices/business-accounts/actions';

const Overview = (): React.JSX.Element => {
  const { t, i18n } = useTranslation('businessAccounts');
  const { businessAccounts, loading } = useAppSelector((state: RootState) => state.businessAccounts);
  const dispatch = useAppDispatch();

  useDocumentTitle(t('overview-page-title'));

  const result = businessAccounts?.reduce((acc: BusinessAccounts[], item: BusinessAccounts) => {
    if (item.currencyCode !== CurrencyEnum.USD) {
      acc.push({
        ...item,
        countryName: countries.getName(item.countryCode, i18n.language),
      });
    }
    return acc;
  }, []);

  const updateAction = (): void => {
    void dispatch(getBusinessAccounts());
  };

  return (
    <div>
      <Typography variant='p1SemiBold'>{t('tabs.overview.balance')}</Typography>
      {loading && <Spinner />}
      {!loading && (
        <Table cols={2}>
          {result?.map(account => (
            <Table.Row key={account.accountId}>
              <Table.Cell>
                <div className={styles.iconCell}>
                  <img
                    src={`/flags/${account.countryCode}.svg`}
                    onError={e => {
                      const defaultSrc = '/flags/Default.svg';
                      if ((e.target as HTMLImageElement).src !== defaultSrc) {
                        (e.target as HTMLImageElement).src = defaultSrc;
                      }
                    }}
                  />
                  <Typography variant='poSemibold'>{account.countryName}</Typography>
                </div>
                <VerticalLine
                  color={marine}
                  size='large'
                />
                <Typography
                  className={styles.currencyCode}
                  variant='poSemibold'
                >
                  {account.currencyCode}
                </Typography>
                <Typography variant='poSemibold'>{account.availableBalance}</Typography>
              </Table.Cell>
              <Table.Cell>
                <BusinessAccountActionButtons
                  accountId={account.accountId}
                  onAction={updateAction}
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table>
      )}
    </div>
  );
};

export default Overview;
