import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import TablePagination from '@/components/common/table-pagination/TablePagination';
import Spinner from '@/components/common/spinners/Spinner';
import TransactionHistoryTable from '@/components/customer/transaction-history/transaction-history-table/TransactionHistoryTable';

import { type RootState, useAppDispatch, useAppSelector } from '@/lib/redux';
import { fetchTransactions } from '@/lib/redux/slices/transaction-history/actions';
import { reset } from '@/lib/redux/slices/transaction-history/slice';
import UserPermissionsGuard from '@/components/user-permissions/UserPermissionsGuard';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDocumentTitle } from '@/utils/hooks/useDocumentTitle';
import TransactionHistoryFilters from './filters/TransactionHistoryFilters';
import {
  useDateRangeFilter,
  usePspTransactionIdFilter,
  useSlipIdFilter,
  useTransactionIdFilter,
  useTransactionStatusFilter,
  useTransactionTypeFilter,
} from './filters/filterHooks';
import usePagination from '@/utils/hooks/usePagination';
import { type ClickOptions } from '@/utils/hooks/useMouseClicks';
import styles from './TransactionHistoryWidget.module.scss';
import Button from '@/components/common/button/Button';
import RefundModal from './RefundModal/RefundModal';
import { Permissions } from '@/types/UserPermissions';
import useItemsPerPage from '@/utils/hooks/useItemsPerPage';

function TransactionHistoryWidget(): React.JSX.Element {
  const { transactions, isLoading, count, page } = useAppSelector((state: RootState) => state.transactionHistory);
  const customer = useAppSelector((state: RootState) => state.customer.customer);
  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = usePagination(page);
  const [itemsPerPage] = useItemsPerPage();
  const loc = useLocation();
  const { t } = useTranslation('customerOverview');
  const [transactionId] = useTransactionIdFilter();
  const [pspTransactionId] = usePspTransactionIdFilter();
  const [slipId] = useSlipIdFilter();
  const [transactionTypes] = useTransactionTypeFilter();
  const [transactionStatus] = useTransactionStatusFilter();
  const [dates] = useDateRangeFilter();

  const [openRefundModal, setOpenRefundModal] = useState(false);

  const navigate = useNavigate();
  /* eslint-disable @typescript-eslint/indent */
  useDocumentTitle(
    customer
      ? t('navigation-tabs.transaction-history-page-title', {
          customerFullName: `${customer.firstName} ${customer.lastName}`,
        })
      : '',
  );

  const [dateFrom, dateTo] = dates;

  useEffect(() => {
    if (customer?.customerId !== undefined) {
      void dispatch(
        fetchTransactions({
          customerId: customer?.customerId,
          page: currentPage,
          itemsPerPage,
          transactionId,
          slipId,
          transactionTypes,
          transactionStatus,
          dateFrom,
          dateTo,
          pspTransactionId,
        }),
      );
    }
  }, [
    itemsPerPage,
    currentPage,
    customer?.customerId,
    transactionId,
    slipId,
    transactionTypes,
    transactionStatus,
    dateFrom,
    dateTo,
    pspTransactionId,
    dispatch,
  ]);

  useEffect(
    () => () => {
      void dispatch(reset());
    },
    [dispatch],
  );

  const onClick = useCallback(
    (id: string, options?: ClickOptions) => {
      const url = `${loc.pathname}${loc.pathname.endsWith('/') ? '' : '/'}${id}`;
      if (options?.isCtrlDown || options?.isMiddleMouseKey) {
        window.open(url, '_blank');
      } else {
        navigate(url);
      }
    },
    [loc, navigate],
  );

  const handleOpenRefundModal = (): void => setOpenRefundModal(true);

  const handleCloseRefundModal = (): void => setOpenRefundModal(false);

  return (
    <>
      <RefundModal
        customerId={customer?.customerId ?? ''}
        open={openRefundModal}
        onClose={handleCloseRefundModal}
      />
      <div className={styles['transactions-widget']}>
        <div className={styles.buttonCard}>
          <TransactionHistoryFilters />
          <UserPermissionsGuard permission={Permissions.transactionsCardrefundEdit}>
            <Button onClick={handleOpenRefundModal}>{t('widgets.transaction-history.refund-button')}</Button>
          </UserPermissionsGuard>
        </div>
        <div className={styles['transactions-widget__table-wrapper']}>
          {isLoading && <Spinner />}
          {!isLoading && (
            <TransactionHistoryTable
              onClick={onClick}
              transactions={transactions}
            />
          )}
        </div>
        <TablePagination
          count={count}
          page={page}
          setPage={setCurrentPage}
        />
      </div>
    </>
  );
}

export default TransactionHistoryWidget;
