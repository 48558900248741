import { type UserRole } from '@/types';
import { type Option } from '@/utils/interfaces';
import { useUserRoles } from './useUserRoles';
import { useMemo } from 'react';

export interface RoleOption extends Option {
  role: UserRole;
}

export function useUserRoleOptions(): RoleOption[] {
  const roles = useUserRoles();

  const roleOptions = useMemo(() => {
    function convertRoleToOption(role: UserRole): RoleOption {
      return { role, text: role.comment ?? role.roleName };
    }
    return roles.map(current => convertRoleToOption(current));
  }, [roles]);

  return roleOptions;
}
