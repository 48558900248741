import authAxios from '@/auth/axios';
import { type AxiosResponse } from 'axios';

export default async function addComment(comment: string, customerId: string): Promise<AxiosResponse> {
  const timestamp = new Date().toISOString();
  return await authAxios.post(
    '/audit/user-comments',
    { description: comment, timestamp },
    { headers: { 'x-customer-id': customerId } },
  );
}
