import { useState } from 'react';
import { useAppSelector, useAppDispatch, type RootState } from '@/lib/redux';
import Button from '@/components/common/button/Button';
import styles from './CountryInformation.module.scss';
import Accordion from '@/components/common/accordion/Accordion';
import { CountryParameters, AccordionTitle } from '@/components/configurations';
import { useDocumentTitle } from '@/utils/hooks/useDocumentTitle';
import { useTranslation } from 'react-i18next';
import { addCountry, countryEdit } from '@/lib/redux/slices/country/actions';
import { objectDeepEquality } from '@/utils/helperFunctions';
import { useNavigate } from 'react-router-dom';

const CountryInformation = (): JSX.Element => {
  const navigate = useNavigate();

  const { country, isLoading } = useAppSelector((state: RootState) => state.country);
  const dispatch = useAppDispatch();

  const { t } = useTranslation('configurations');
  useDocumentTitle(t('new-country.page-title'));

  const [activeIndices, setActiveIndices] = useState<number[]>([]);

  const [parameters, setParameters] = useState({
    countryCode: country.countryCode,
    countryName: country.countryName,
    isEnabled: country.isEnabled,
    isTesting: country.isTesting,
    internationalCallingCode: country.internationalCallingCode,
    minPhoneLength: country.minPhoneLength,
    maxPhoneLength: country.maxPhoneLength,
    currencies: country.currencies,
  });

  const handleClick = (index: number): void => {
    setActiveIndices(prevValue =>
      prevValue.includes(index) ? prevValue.filter(i => i !== index) : [...prevValue, index],
    );
  };

  const { isEnabled, isTesting, ...excludeParameters } = parameters;
  const { isEnabled: countryIsEnabled, isTesting: countryIsTesting, ...excludeCountry } = country;

  const isNotChanged = objectDeepEquality(excludeCountry, excludeParameters);

  const handleSubmit = (): void => {
    if (country.currencies.length === 0) {
      void dispatch(addCountry({ body: parameters }));
    }
    if (!isNotChanged && country.currencies.length > 0) {
      const { countryCode, isEnabled, isTesting, ...filteredParameters } = parameters;
      void dispatch(countryEdit({ body: filteredParameters, countryCode: parameters.countryCode.toUpperCase() }));
    }
  };

  const areCurrenciesValid = (): boolean => {
    if (parameters.currencies.length !== 2) {
      return false;
    }

    return parameters.currencies.every(
      currency =>
        currency &&
        currency.currencyCode?.length > 0 &&
        currency.currencyName?.length > 0 &&
        currency.symbol?.length > 0 &&
        currency.position?.length > 0 &&
        typeof currency.precision === 'number',
    );
  };

  const hasValidInputs = (): boolean => {
    const countryCodeValid = parameters.countryCode.length > 0;
    const countryNameValid = parameters.countryName.length > 0;
    const internationalCallingCodeValid = parameters.internationalCallingCode > 0;
    const minPhoneLengthValid = parameters.minPhoneLength > 0;
    const maxPhoneLengthValid = parameters.maxPhoneLength > 0;
    const currenciesValid = areCurrenciesValid();

    return (
      countryCodeValid &&
      countryNameValid &&
      currenciesValid &&
      internationalCallingCodeValid &&
      minPhoneLengthValid &&
      maxPhoneLengthValid
    );
  };

  const handleCancel = (): void => navigate('/configurations/countries');

  const items = [
    {
      key: 1,
      title: (
        <AccordionTitle
          isValid={country.currencies.length !== 0 && isNotChanged}
          title='Country parameters'
        />
      ),
      content: (
        <CountryParameters
          parameters={parameters}
          setParameters={setParameters}
        />
      ),
    },
  ];

  return (
    <>
      <div>
        {items.map((item, index) => (
          <Accordion
            key={item.key}
            title={item.title}
            content={item.content}
            isActive={activeIndices.includes(item.key)}
            onClick={() => handleClick(item.key)}
          />
        ))}
      </div>
      <div className={styles.buttons}>
        <Button
          onClick={handleCancel}
          variant='secondary'
          textTransform='uppercase'
        >
          {t('new-country.button-cancel')}
        </Button>
        <Button
          textTransform='uppercase'
          disabled={isNotChanged || isLoading || !hasValidInputs()}
          onClick={handleSubmit}
        >
          {t('new-country.button-submit-configuration')}
        </Button>
      </div>
    </>
  );
};

export default CountryInformation;
