import { useTranslation } from 'react-i18next';
import BlockButton from '@/components/common/button/implementations/BlockButton';
import styles from './Actions.module.scss';
import CloseAccountButton from '@/components/common/button/implementations/CloseAccountButton';
import { useState } from 'react';
import ConfirmationModal from '@/components/common/modals/confirmation/ConfirmModal';
import UnblockButton from '@/components/common/button/implementations/UnblockButton';
import UserPermissionsGuard from '@/components/user-permissions/UserPermissionsGuard';
import { Permissions } from '@/types';
import LinkButton from '@/components/common/button/LinkButton';

export interface ActionsProps extends ShowButtonsInterface {
  customerId: string;
  onUnblock: (comment: string) => void;
  onBlock: (comment: string) => void;
  onCloseAccount: (comment: string) => void;
  onResetLogAttempts: (comment: string) => void;
}

export interface ShowButtonsInterface {
  showBlockButton?: boolean;
  showUnblockButton?: boolean;
  showCloseAccountButton?: boolean;
  showLoginAttemptsButton?: boolean;
}

export default function Actions({
  customerId,
  onUnblock,
  onBlock,
  onCloseAccount,
  showBlockButton,
  showUnblockButton,
  showCloseAccountButton,
  showLoginAttemptsButton,
  onResetLogAttempts,
}: Readonly<ActionsProps>): React.JSX.Element {
  const [showActivateModal, setShowActivateModal] = useState(false);
  const [showBlockModal, setShowBlockModal] = useState(false);
  const [showCloseAccountModal, setShowCloseAccountModal] = useState(false);
  const [showResetLoginAttemptsModal, setShowResetLoginAttemptsModal] = useState(false);
  const { t } = useTranslation('customerOverview');

  return (
    <>
      {showActivateModal && (
        <ConfirmationModal
          title={t('widgets.account-management.confirm-unblock-account-message', { customerId })}
          onDeny={() => {
            setShowActivateModal(false);
          }}
          onConfirm={comment => {
            setShowActivateModal(false);
            onUnblock(comment ?? '');
          }}
          hasComment
        />
      )}
      {showBlockModal && (
        <ConfirmationModal
          title={t('widgets.account-management.confirm-block-account-message', { customerId })}
          onDeny={() => {
            setShowBlockModal(false);
          }}
          onConfirm={comment => {
            setShowBlockModal(false);
            onBlock(comment ?? '');
          }}
          hasComment
        />
      )}
      {showCloseAccountModal && (
        <ConfirmationModal
          title={t('widgets.account-management.confirm-close-account-message', { customerId })}
          onDeny={() => {
            setShowCloseAccountModal(false);
          }}
          onConfirm={comment => {
            setShowCloseAccountModal(false);
            onCloseAccount(comment ?? '');
          }}
          hasComment
        />
      )}
      {showResetLoginAttemptsModal && (
        <ConfirmationModal
          title={t('widgets.account-management.confirm-reset-login-attempts', { customerId })}
          onDeny={() => {
            setShowResetLoginAttemptsModal(false);
          }}
          onConfirm={comment => {
            setShowResetLoginAttemptsModal(false);
            onResetLogAttempts(comment ?? '');
          }}
          hasComment
        />
      )}
      <div className={`${styles.container}`}>
        {showLoginAttemptsButton && (
          <UserPermissionsGuard permission={Permissions.customerManage}>
            <LinkButton onClick={() => setShowResetLoginAttemptsModal(true)}>
              {t('widgets.account-management.reset-login-attempts-button')}
            </LinkButton>
          </UserPermissionsGuard>
        )}
        {showUnblockButton && (
          <UnblockButton
            onClick={() => {
              setShowActivateModal(true);
            }}
          />
        )}
        {showBlockButton && (
          <BlockButton
            onClick={() => {
              setShowBlockModal(true);
            }}
          />
        )}
        {showCloseAccountButton && (
          <CloseAccountButton
            onClick={() => {
              setShowCloseAccountModal(true);
            }}
          />
        )}
      </div>
    </>
  );
}
