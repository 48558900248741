import { CustomerDocStatus, Permissions, type CustomerAddressDoc } from '@/types';
import styles from './AddressDocsContent.module.scss';
import Typography from '@/components/common/typography/Typography';
import UserPermissionsGuard from '@/components/user-permissions/UserPermissionsGuard';
import Button from '@/components/common/button/Button';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@/lib/redux';
import { setSelectedDocument } from '@/lib/redux/slices/document-review/slice';
import ConfirmationModal from '../review/ConfirmationModal';
import { toUtcDateString } from '@/utils/dateTimeUtils';
import { toTitleCase } from '@/utils/typography';
import { Navigate } from 'react-router-dom';
import { AppUrls } from '@/utils/constants';

export default function AddressDocsContent({
  addressDoc,
  review,
}: Readonly<{ addressDoc: CustomerAddressDoc; review?: boolean }>): React.JSX.Element {
  const { t } = useTranslation('documentReviewQueue');
  const { confirmedStatus } = useAppSelector(state => state.documentReview);

  const dispatch = useAppDispatch();

  const onDeclineButtonClick = (): void => {
    dispatch(setSelectedDocument({ status: CustomerDocStatus.REJECTED, documentId: addressDoc.addressDocId }));
  };

  const onApproveButtonClick = (): void => {
    dispatch(setSelectedDocument({ status: CustomerDocStatus.APPROVED, documentId: addressDoc.addressDocId }));
  };

  if (review && (confirmedStatus ?? addressDoc.status !== CustomerDocStatus.REVIEW)) {
    const actualStatus = confirmedStatus ?? addressDoc.status;
    const status = actualStatus === CustomerDocStatus.APPROVED ? 'approved' : 'declined';

    return (
      <div className={styles.main}>
        <div className={styles['white-box']}>
          <Typography className={styles['reviewed-label']}>
            {t('document-review-page.review-status-message', { status: t(status) })}.
          </Typography>
        </div>
      </div>
    );
  }

  if (!review && addressDoc.status !== CustomerDocStatus.APPROVED) {
    return <Navigate to={AppUrls.NotFoundUrl} />;
  }

  return (
    <>
      <ConfirmationModal />
      <div className={styles.main}>
        <div className={styles['white-box']}>
          <div className={styles.documents}>
            <div className={styles['info-wrapper']}>
              <Typography variant='p3Light'>{toUtcDateString(new Date())}</Typography>
              <Typography variant='p3Light'>Address document</Typography>
              <Typography variant='p3Light'>{toTitleCase(addressDoc.status ?? CustomerDocStatus.REVIEW)}</Typography>
            </div>
            <div className={styles['img-container']}>
              <img
                src={`data:image/${addressDoc.fileType};base64,${addressDoc.file}`}
                alt='address-document'
              />
            </div>
          </div>
          {review && (
            <UserPermissionsGuard permission={Permissions.docQueueAdmit}>
              <div className={styles.buttons}>
                <Button
                  variant='secondary'
                  onClick={onDeclineButtonClick}
                  textTransform='uppercase'
                >
                  {t('decline')}
                </Button>
                <Button
                  onClick={onApproveButtonClick}
                  textTransform='uppercase'
                >
                  {t('approve')}
                </Button>
              </div>
            </UserPermissionsGuard>
          )}
        </div>
      </div>
    </>
  );
}
