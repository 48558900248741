import { type CurrencyEnum } from './Currencies';

export enum TransactionStatus {
  Pending = 'Pending',
  Failed = 'Failed',
  Declined = 'Declined',
  Expired = 'Expired',
  Approved = 'Approved',
  Initiated = 'Initiated',
  InProgress = 'InProgress',
}

export enum TransactionType {
  Topup = 'Topup',
  Withdrawal = 'Withdrawal',
  Transfer = 'Transfer',
  Card = 'Card',
  PaymentFromCustomer = 'PaymentFromCustomer',
  PaymentToCustomer = 'PaymentToCustomer',
  MerchantTopUp = 'MerchantTopUp',
  MerchantWithdrawal = 'MerchantWithdrawal',
  Exchange = 'Exchange',
  AdjustmentCredit = 'AdjustmentCredit',
  AdjustmentDebit = 'AdjustmentDebit',
  Chargeback = 'Chargeback',
  WalletRefundDebit = 'WalletRefundDebit',
  WalletRefundCredit = 'WalletRefundCredit',
  ChargebackReversal = 'ChargebackReversal',
  Reversal = 'Reversal',
  MerchantPaymentReversal = 'MerchantPaymentReversal',
}

interface Source {
  feeTotalAmount?: number;
  balanceAmountCurrency?: string;
  customerId?: string;
  balanceAmount?: number;
  accountId?: string;
  allowedToShowTrx?: boolean;
}

interface Destination {
  feeTotalAmount: number;
  customerId: string;
  balanceAmount?: number;
  balanceAmountCurrency?: string;
}

interface PaymentDetail {
  paymentMethodName: string;
  providerTransactionId: string;
}

export interface Transaction {
  transactionId?: string;
  slipId?: string;
  paymentDetail?: PaymentDetail;
  requestedAmount?: number;
  destination?: Destination;
  providerTransactionId?: string;
  source?: Source;
  expireDate?: Date | string;
  requestedAmountCurrency?: CurrencyEnum;
  status?: TransactionStatus;
  transactionType?: TransactionType;
  statusReason?: string;
  createdDate?: Date | string;
  updatedDate?: Date | string;
}
