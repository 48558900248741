import React, { useCallback, useMemo } from 'react';
import ConfirmModalElement from '@/components/common/modals/confirmation/ConfirmModal';
import { type RootState, useAppDispatch, useAppSelector } from '@/lib/redux';
import { updateStatus } from '@/lib/redux/slices/document-review/actions';
import styled from 'styled-components';
import { useKycApplications } from '@/utils/hooks/useKycApplications';
import { useTranslation } from 'react-i18next';
import { reset } from '@/lib/redux/slices/document-review/slice';
import { CustomerDocStatus } from '@/types';

const TitleDiv = styled.div`
  text-align: center;
`;

export default function ConfirmationModal(): React.JSX.Element | null {
  const { selectedStatus, documentId, loading } = useAppSelector(state => state.documentReview);
  const { customer } = useAppSelector((state: RootState) => state.customer);
  const dispatch = useAppDispatch();
  const { t } = useTranslation('documentReviewQueue');

  const kycApplication = useKycApplications(customer?.customerId ?? '');

  const onClose = useCallback(() => {
    dispatch(reset());
  }, [dispatch]);

  const onConfirm = useCallback(() => {
    if (customer?.customerId && documentId && selectedStatus && kycApplication.id) {
      void dispatch(
        updateStatus({
          kycApplicationId: kycApplication.id,
          status: selectedStatus,
          documentId,
        }),
      );
    }
  }, [dispatch, customer?.customerId, documentId, selectedStatus, kycApplication.id]);

  const action = useMemo(() => {
    return selectedStatus === CustomerDocStatus.APPROVED ? 'approve' : 'decline';
  }, [selectedStatus]);

  if (!selectedStatus) {
    return null;
  }

  return (
    <ConfirmModalElement
      onConfirm={onConfirm}
      onDeny={onClose}
      title={<TitleDiv>{t('confirmation-modal.confirm-document-message', { action: t(action) })}</TitleDiv>}
      disabled={loading}
    />
  );
}
