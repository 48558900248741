import { useState } from 'react';
import Modal from '../Modal';
import Button from '@/components/common/button/Button';
import styles from './ConfirmModal.module.scss';
import Input from '@/components/common/inputs/Input';
import InputLabel from '@/components/common/labels/InputLabel';
import { useTranslation } from 'react-i18next';

export default function ConfirmationModal({
  title,
  onDeny,
  onConfirm,
  hasComment,
  disabled,
}: Readonly<{
  title: React.ReactNode;
  onDeny: () => void;
  onConfirm: (comment?: string) => void;
  hasComment?: boolean;
  disabled?: boolean;
}>): React.JSX.Element {
  const [comment, setComment] = useState('');
  const { t } = useTranslation('global');

  const onConfirmClick: () => void = () => {
    onConfirm(hasComment !== undefined && hasComment ? comment : undefined);
  };

  return (
    <Modal onClose={onDeny}>
      <div className={styles.main}>
        <div className={styles.title}>{title}</div>
        {hasComment !== undefined && hasComment && (
          <InputLabel
            label={t('confirm-modal.insert-comment-message')}
            className={styles.comment}
          >
            <Input
              value={comment}
              onChange={(newValue: string) => setComment(newValue)}
            />
          </InputLabel>
        )}
        <div className={styles.buttons}>
          <Button
            variant='secondary'
            onClick={onDeny}
            disabled={disabled}
            textTransform='uppercase'
          >
            {t('confirm-modal.no')}
          </Button>
          <Button
            onClick={onConfirmClick}
            disabled={disabled}
            textTransform='uppercase'
          >
            {t('confirm-modal.yes')}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
