import type { Action, ThunkAction } from '@reduxjs/toolkit';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { customerSlice } from './slices/customer/slice';
import { authSlice } from './slices/auth/slice';
import { accountsSlice } from './slices/accounts/slice';
import { documentReviewQueueSlice } from './slices/document-review-queue/slice';
import { userPermissionsSlice } from './slices/user-permissions/slice';
import { deviceInfoSlice } from './slices/device-info/slice';
import { transactionHistorySlice } from './slices/transaction-history/slice';
import { kycApplicationsSlice } from './slices/kyc-applications/slice';
import { identityDocumentsSlice } from './slices/identity-docs/slice';
import { paymentCardsSlice } from './slices/payment-cards/slice';
import { singleTransactionHistorySlice } from '@/lib/redux/slices/transaction-history-detail/slice';
import { auditLogsCommentSlice } from './slices/audit-logs-comment/slice';
import { auditLogsSlice } from './slices/audit-logs/slice';
import { customerAddressDocumentsSlice } from './slices/customer-address-docs/slice';
import { addressDocsSlice } from './slices/address-docs/slice';
import { businessAccountsSlice } from './slices/business-accounts/slice';
import { docReviewSlice } from './slices/document-review/slice';
import { transactionHistoryBASlice } from './slices/transaction-history-ba/slice';
import { usersSlice } from './slices/users/slice';
import { loginAttemptsSlice } from './slices/password/slice';
import { rolesAndPermissionsSlice } from './slices/roles-and-permissions/slice';
import { updateUserDetailsSlice } from './slices/user-edit/slice';
import { customerCardsSlice } from './slices/customer-cards/slice';
import { transactionWithdrawalSlice } from './slices/transaction-withdrawal/slice';
import { transactionTransfersSlice } from './slices/transaction-transfer/slice';
import { transactionCustomerPaymentSlice } from './slices/transaction-customer-payment/slice';
import { transactionAdjustmentSlice } from './slices/transaction-adjustment/slice';
import { transactionChargebacksSlice } from './slices/transaction-chargebacks/slice';
import { transactionExchangeSlice } from '@/lib/redux/slices/transaction-exchange/slice';
import { walletRefundsSlice } from './slices/wallet-refunds/slice';
import { customerCardDetailsSlice } from './slices/customer-card-details/slice';
import { transactionReversalSlice } from '@/lib/redux/slices/transaction-reversal/slice';
import { transactionChargebackSlice } from '@/lib/redux/slices/transaction-chargeback/slice';
import { transactionChargebackReversalSlice } from '@/lib/redux/slices/transaction-chargeback-reversal/slice';
import { chargebackReversalsSlice } from '@/lib/redux/slices/transaction-chargeback-reversals/slice';
import { transactionActionsSlice } from '@/lib/redux/slices/transaction-actions/slice';
import { countrySlice } from '@/lib/redux/slices/country/slice';
import { transactionExpireCardSlice } from '@/lib/redux/slices/transaction-expire-card/slice';
import { countriesSlice } from '@/lib/redux/slices/countries/slice';

const combinedReducer = combineReducers({
  auth: authSlice.reducer,
  customer: customerSlice.reducer,
  accounts: accountsSlice.reducer,
  documentReviewQueue: documentReviewQueueSlice.reducer,
  userPermissions: userPermissionsSlice.reducer,
  deviceInfo: deviceInfoSlice.reducer,
  transactionHistory: transactionHistorySlice.reducer,
  kycApplicationsState: kycApplicationsSlice.reducer,
  identityDocs: identityDocumentsSlice.reducer,
  documentReview: docReviewSlice.reducer,
  paymentCardsState: paymentCardsSlice.reducer,
  auditLogsComment: auditLogsCommentSlice.reducer,
  auditLogsSlice: auditLogsSlice.reducer,
  singleTransactionSlice: singleTransactionHistorySlice.reducer,
  transactionActionsSlice: transactionActionsSlice.reducer,
  transactionExchangeSlice: transactionExchangeSlice.reducer,
  transactionReversalSlice: transactionReversalSlice.reducer,
  transactionExpireCardSlice: transactionExpireCardSlice.reducer,
  transactionChargebackSlice: transactionChargebackSlice.reducer,
  transactionChargebackReversalSlice: transactionChargebackReversalSlice.reducer,
  customerAddressDocs: customerAddressDocumentsSlice.reducer,
  addressDocsSlice: addressDocsSlice.reducer,
  transactionHistoryBA: transactionHistoryBASlice.reducer,
  businessAccounts: businessAccountsSlice.reducer,
  users: usersSlice.reducer,
  rolesAndPermissions: rolesAndPermissionsSlice.reducer,
  loginAttempts: loginAttemptsSlice.reducer,
  updateUserDetailsSlice: updateUserDetailsSlice.reducer,
  customerCards: customerCardsSlice.reducer,
  customerCardDetails: customerCardDetailsSlice.reducer,
  transactionWithdrawalSlice: transactionWithdrawalSlice.reducer,
  transactionTransfersSlice: transactionTransfersSlice.reducer,
  transactionCustomerPayment: transactionCustomerPaymentSlice.reducer,
  transactionAdjustment: transactionAdjustmentSlice.reducer,
  transactionChargebacks: transactionChargebacksSlice.reducer,
  walletRefunds: walletRefundsSlice.reducer,
  chargebackReversals: chargebackReversalsSlice.reducer,
  country: countrySlice.reducer,
  countries: countriesSlice.reducer,
});

export const store = configureStore({
  reducer: combinedReducer,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
