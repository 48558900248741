import { createContext, useState, useEffect, useMemo, type Dispatch, type SetStateAction } from 'react';
import { type Transaction } from '@/types';
import { useTranslation } from 'react-i18next';
import ActionModal from '@/components/customer/kyc-risk/audit-logs/ActionModal/ActionModal';
import styles from '@/components/customer/transaction-history/transaction-actions/TransactionActions.module.scss';
import ReversalAction from '@/components/customer/transaction-history/transaction-actions/actions/ReversalAction';
import ChargebackAction from '@/components/customer/transaction-history/transaction-actions/actions/ChargebackAction';
import AdjustmentDebitAction from '@/components/customer/transaction-history/transaction-actions/actions/AdjustmentDebitAction';
import AdjustmentCreditAction from '@/components/customer/transaction-history/transaction-actions/actions/AdjustmentCreditAction';
import ChargebackReversal from '@/components/customer/transaction-history/transaction-actions/actions/ChargebackReversalAction';
import Headers from '@/components/customer/transaction-history/transaction-actions/Headers';
import MerchantPaymentReversalAction from '@/components/customer/transaction-history/transaction-actions/actions/MerchantPaymentReversalAction';
import UserPermissionsGuard from '@/components/user-permissions/UserPermissionsGuard';
import { Permissions } from '@/types';
import Spinner from '@/components/common/spinners/Spinner';
import Typography from '@/components/common/typography/Typography';
import { mediumPurple } from '@/utils/colors';
import { usePermissions } from '@/utils/hooks/usePermissions';
import CloudsIcon from '@/components/icons/CloudsIcon';
import ExpireCardAction from '@/components/customer/transaction-history/transaction-actions/actions/ExpireCardAction';

export interface TransactionActionsProps {
  open: boolean;
  onClose: (params?: ActionOnCloseParams) => void;
  transaction?: Transaction;
}

export type Accessibility = boolean | undefined;

type ReadonlyTransactionActionsProps = Readonly<TransactionActionsProps>;

export enum TransactionActionType {
  AdjustmentCredit = 'AdjustmentCredit',
  AdjustmentDebit = 'AdjustmentDebit',
  Chargeback = 'Chargeback',
  ChargebackReversal = 'ChargebackReversal',
  Reversal = 'Reversal',
  MerchantPaymentReversal = 'MerchantPaymentReversal',
  ExpireCard = 'ExpireCard',
}

export interface ActionProps {
  onClose: (params?: ActionOnCloseParams) => void;
  setAccessibility: Dispatch<SetStateAction<Accessibility>>;
  transaction?: Transaction;
}

export interface ActionOnCloseParams {
  success?: boolean;
  refreshTransaction?: boolean;
  disableNavigate?: boolean;
}

interface TransactionActionsContextProps {
  selectedTransactionAction?: TransactionActionType;
  setSelectedTransactionAction: (type: TransactionActionType | undefined) => void;
}

const TransactionActionsContext = createContext<TransactionActionsContextProps>({
  selectedTransactionAction: undefined,
  setSelectedTransactionAction: () => {},
});

const TransactionActions = (props: ReadonlyTransactionActionsProps): JSX.Element => {
  const { t } = useTranslation(['customerOverview', 'global']);
  const { open, onClose, transaction } = props;

  const [adjustmentCredit, setAdjustmentCredit] = useState<Accessibility>();
  const [adjustmentDebit, setAdjustmentDebit] = useState<Accessibility>();
  const [chargeback, setChargeback] = useState<Accessibility>();
  const [chargebackReversal, setChargebackReversal] = useState<Accessibility>();
  const [reversal, setReversal] = useState<Accessibility>();
  const [merchantPaymentReversal, setMerchantPaymentReversal] = useState<Accessibility>();
  const [expireCard, setExpireCard] = useState<Accessibility>();

  const hasAdjustmentPermission = usePermissions(Permissions.transactionsAdjustmentEdit);
  const hasChargebackPermission = usePermissions(Permissions.transactionsChargebackEdit);
  const hasExpirePermission = usePermissions(Permissions.expireAuthEdit);

  const [selectedTransactionAction, setSelectedTransactionAction] = useState<TransactionActionType | undefined>(
    undefined,
  );
  const isLoading =
    adjustmentCredit === undefined ||
    adjustmentDebit === undefined ||
    chargeback === undefined ||
    chargebackReversal === undefined ||
    reversal === undefined ||
    merchantPaymentReversal === undefined ||
    expireCard === undefined;

  const noItems =
    !isLoading &&
    !adjustmentCredit &&
    !adjustmentDebit &&
    !chargeback &&
    !chargebackReversal &&
    !reversal &&
    !merchantPaymentReversal &&
    !expireCard;

  useEffect(() => {
    if (hasAdjustmentPermission === false) {
      setAdjustmentCredit(false);
      setAdjustmentDebit(false);
    }
    if (hasChargebackPermission === false) {
      setChargeback(false);
      setChargebackReversal(false);
    }
    if (hasExpirePermission === false) {
      setExpireCard(false);
    }
  }, [hasAdjustmentPermission, hasChargebackPermission, hasExpirePermission]);

  const contextValue = useMemo(
    () => ({ selectedTransactionAction, setSelectedTransactionAction }),
    [selectedTransactionAction],
  );

  return (
    <TransactionActionsContext.Provider value={contextValue}>
      <ActionModal
        open={open}
        disabledButton={true}
        onClose={onClose}
      >
        <Headers
          transaction={transaction}
          action={selectedTransactionAction}
        />
        <div>
          {isLoading && <Spinner />}
          <div
            style={{ display: isLoading ? 'none' : 'flex' }}
            className={styles.contentWrapper}
          >
            <UserPermissionsGuard permission={Permissions.transactionsAdjustmentEdit}>
              <>
                <AdjustmentCreditAction
                  setAccessibility={setAdjustmentCredit}
                  {...props}
                />
                <AdjustmentDebitAction
                  setAccessibility={setAdjustmentDebit}
                  {...props}
                />
              </>
            </UserPermissionsGuard>
            <UserPermissionsGuard permission={Permissions.transactionsChargebackEdit}>
              <>
                <ChargebackAction
                  setAccessibility={setChargeback}
                  {...props}
                />
                <ChargebackReversal
                  setAccessibility={setChargebackReversal}
                  {...props}
                />
              </>
            </UserPermissionsGuard>
            <ReversalAction
              setAccessibility={setReversal}
              {...props}
            />
            <MerchantPaymentReversalAction
              setAccessibility={setMerchantPaymentReversal}
              {...props}
            />
            <UserPermissionsGuard permission={Permissions.expireAuthEdit}>
              <ExpireCardAction
                setAccessibility={setExpireCard}
                {...props}
              />
            </UserPermissionsGuard>
          </div>
          {noItems && (
            <>
              <div className={styles.icon}>
                <CloudsIcon
                  height='7rem'
                  width='30rem'
                />
              </div>
              <div className={styles.noAction}>
                <Typography
                  color={mediumPurple.cssColor}
                  variant='p3'
                >
                  {t('widgets.transaction-history.modal-input-no-items')}
                </Typography>
              </div>
            </>
          )}
        </div>
      </ActionModal>
    </TransactionActionsContext.Provider>
  );
};

export { TransactionActions, TransactionActionsContext };
